body {
    /*overflow: hidden;*/
    /*background-color: #212121;*/
}

.page-container {
    /*background-image: url('./img/brand/bg.png');*/
    background-size: cover;
    min-height: 100vh;
}
body {
    overflow-x: hidden;
}

.hero-container {
    /*background-image: url('./img/brand/bg.png');*/
    background-size: cover;
    min-height: 90vh;
}

.footer-cta-container {
    /*background-image: url('./img/brand/bg.png');*/
    background-size: cover;
    min-height: 80vh;
}

.hero-mockup-container {
    /*margin-top: -28vh;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gradient-text-light {
    background: radial-gradient(2329.97% 432.28% at 83.33% 131.53%, #FFF 0%, #929CF1 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-light-2 {
    background: radial-gradient(2329.97% 432.28% at 83.33% 131.53%, #B2B2FF 0.62%, #B7BFFF 40.72%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-dark {
    background: radial-gradient(2329.97% 432.28% at 83.33% 131.53%, #2C2C59 0%, #929CF1 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-img {
    width: 95vw
}
.nav-logo {
    height: 32px;
}

.launchlist-input-wrapper input {
    background-color: #21212120 !important;
}

@media screen and (max-width: 600px) {
    .slider-img {
        width: 600px;
    }

    .main-text {
        margin-top: 10vh;
    }

    .hide-on-mobile {
        display: none !important;
    }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .slider-img {
        width: 700px;
    }

    .heading1 {
        font-size: 60px !important;
    }

    .main-text {
        margin-top: 10vh;
    }

    .only-mobile {
        display: none !important;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1299px) {
    .slider-img {
        width: 680px;
    }

    .main-text {
        margin-top: 10vh;
    }

    .only-mobile {
        display: none !important;
    }
}

@media screen and (min-width: 1300px) {
    .slider-img {
        width: 800px;
    }

    .only-mobile {
        display: none !important;
    }
}

.custom-flex-child {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 0.5rem); /* Adjust the value as per your spacing needs */
}

.search-input {
    background-color: white;
    border-radius: 100px;
    border: 1px solid #00000020;
    outline: none;
    height: 42px;
    padding: 0 24px 0 60px;
}

.search-result {
    background-color: white;
    cursor: pointer;
}

.search-result:hover {
    background-color: #ffffffA0;
    /*border: 2px solid;*/
}
