
.gallery-item {
    position: relative;
    overflow: hidden;
    /*background-color: var(--base-black-4);*/
    padding: 20px;
    /*height: 600px;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    transition: 150ms ease-in-out;
    border: 1px solid var(--base-white-20);
    color: var(--base-white-100)
}

.gallery-item:hover {
    transform: scale(1.01);
    border: 1px solid var(--base-white-50);
}

.gallery-item__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background: linear-gradient(-12deg, var(--base-black-100), var(--base-black-10));
    transition: 300ms ease-in-out;
}

.gallery-item:hover .gallery-item__overlay {
    opacity: .5;
    background: linear-gradient(12deg, var(--base-black-100), var(--base-black-30));
}

.gallery-item__content {
    opacity: 0;
    transform: translateY(8px);
    transition: 150ms ease-in-out;
}

.gallery-tag {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.gallery-item:hover .gallery-item__content {
    opacity: 1;
    transform: translateY(0px);
}

.gallery-item__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 1;
}

/*.grid-container {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(2, 1fr);*/
/*    grid-auto-rows: minmax(60vh, auto);*/
/*    gap: 10px;*/
/*}*/

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
}

.grid-item {
    border: 1px solid var(--base-white-20);
    min-height: 60vh;
    /*height: min-content;*/
    padding: 20px 20px;
    overflow: hidden;
}

.grid-item img {
    transition: 300ms ease-in-out;
}

.grid-item:hover img {
    transform: scale(1.01);
}

.grid-item-mobile {
    border: 1px solid var(--base-white-20);
    /*min-height: 60vh;*/
    /*height: min-content;*/
    padding: 12px;
    overflow: hidden;
}

.grid-item-mobile img {
    transition: 300ms ease-in-out;
}

.grid-item-mobile:hover img {
    transform: scale(1.01);
}


/*.grid-item-1 { grid-area: 1 / 1 / 2 / 3; }*/
/*.grid-item-2 { grid-area: 1 / 3 / 2 / 4; }*/
/*.grid-item-3 { grid-area: 2 / 2 / 3 / 4; }*/
/*.grid-item-4 { grid-area: 2 / 1 / 3 / 2; }*/
/*.grid-item-5 { grid-area: 3 / 1 / 5 / 2; }*/
/*.grid-item-6 { grid-area: 3 / 2 / 4 / 4; }*/
/*.grid-item-7 { grid-area: 4 / 2 / 5 / 3; }*/
/*.grid-item-8 { grid-area: 4 / 3 / 5 / 4; }*/

.finary-web-grid-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.finary-web-grid-item-1 { grid-area: 1 / 1 / 4 / 5; }
.finary-web-grid-item-2 { grid-area: 1 / 5 / 4 / 8; }
.finary-web-grid-item-3 { grid-area: 4 / 1 / 7 / 8; }
.finary-web-grid-item-4 { grid-area: 7 / 1 / 10 / 4; }
.finary-web-grid-item-5 { grid-area: 7 / 4 / 10 / 8; }
.finary-web-grid-item-6 { grid-area: 10 / 1 / 13 / 5; }
.finary-web-grid-item-7 { grid-area: 10 / 5 / 13 / 8; }

.starfish-web-grid-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.starfish-web-grid-item-1 { grid-area: 1 / 1 / 4 / 4; }
.starfish-web-grid-item-2 { grid-area: 1 / 4 / 4 / 8; }
.starfish-web-grid-item-3 { grid-area: 4 / 1 / 7 / 8; }
.starfish-web-grid-item-4 { grid-area: 7 / 1 / 10 / 5; }
.starfish-web-grid-item-5 { grid-area: 7 / 5 / 10 / 8; }

.finary-app-grid-container {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.finary-app-grid-item-1 { grid-area: 1 / 1 / 4 / 6; }
.finary-app-grid-item-2 { grid-area: 1 / 6 / 4 / 10; }
.finary-app-grid-item-3 { grid-area: 4 / 1 / 7 / 5; }
.finary-app-grid-item-4 { grid-area: 4 / 5 / 7 / 10; }


.weedwise-web-grid-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.weedwise-web-grid-item-1 { grid-area: 1 / 1 / 4 / 4; }
.weedwise-web-grid-item-2 { grid-area: 1 / 4 / 4 / 8; }
.weedwise-web-grid-item-3 { grid-area: 4 / 1 / 7 / 8; }
.weedwise-web-grid-item-4 { grid-area: 7 / 1 / 10 / 5; }
.weedwise-web-grid-item-5 { grid-area: 7 / 5 / 10 / 8; }
.weedwise-web-grid-item-6 { grid-area: 10 / 1 / 10 / 8; }


.dil-grid-container {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.dil-grid-item-1 { grid-area: 1 / 1 / 4 / 6; }
.dil-grid-item-2 { grid-area: 1 / 6 / 4 / 10; }
.dil-grid-item-3 { grid-area: 4 / 1 / 7 / 5; }
.dil-grid-item-4 { grid-area: 4 / 5 / 7 / 10; }
