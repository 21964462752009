/*CAPABILITIES SECTION*/

.services-section {
    /*min-height: 55vh;*/
    /*border-top: 1px solid var(--base-black-20);*/
    border-bottom: 1px solid var(--base-black-20);
}

.services-section-left {
    border-right: 1px solid var(--base-black-20);
}

.services-section-right {
    border-top: 1px solid var(--base-black-20);
    padding-right: 15vw !important;
}

.capability-list-item {
    border-top: 1px solid var(--base-black-20);
    display: flex;
    flex-direction: column;
    transition: all 150ms ease-in-out;
    padding-left: var(--page-h-padding);
}

.capability-list-item:hover {
    background-color: var(--base-black-4);
}

.capability-list-item p {
    transition: 150ms ease-in-out;
}

/*.capability-list-item:hover p {*/
/*    transform: scale(1.1);*/
/*}*/

/*FEATURE SECTION*/

.feature-tile {
    border: 1.5px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feature-tile-dark {
    border: 1.5px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*HERO*/

.hero-badge {
    bottom: -42px;
    left: var(--page-h-padding);
    /*background-color: var(--base-white-30);*/
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    shape-outside: circle();
    float: left;
    /*animation: rotate 26s linear infinite;*/
    /*margin-right: 20px;*/
}

.badge-outer {
    width: 18vw;
    animation: rotate 60s linear infinite;
}

.badge-outer-mobile {
    width: 40vw;
    animation: rotate 60s linear infinite;
}

.rotate {
    animation: rotate 60s linear infinite;
}

.rotate-fast {
    animation: rotate 12s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.float {
    animation: float 80s ease-in-out infinite;
}


@keyframes float {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    5% {
        transform: translate(-3%, -5%) rotate(-3deg);
    }
    10% {
        transform: translate(-2%, -2%) rotate(-2deg);
    }
    15% {
        transform: translate(-1%, -5%) rotate(-1deg);
    }
    20% {
        transform: translate(0, 0) rotate(0);
    }
    25% {
        transform: translate(1%, -5%) rotate(1deg);
    }
    30% {
        transform: translate(2%, -2%) rotate(2deg);
    }
    35% {
        transform: translate(3%, -5%) rotate(3deg);
    }
    40% {
        transform: translate(0, 0) rotate(0);
    }
    45% {
        transform: translate(-2%, 5%) rotate(-2deg);
    }
    50% {
        transform: translate(-1%, 2%) rotate(-1deg);
    }
    55% {
        transform: translate(-3%, 5%) rotate(-3deg);
    }
    60% {
        transform: translate(0, 0) rotate(0);
    }
    65% {
        transform: translate(3%, 5%) rotate(3deg);
    }
    70% {
        transform: translate(1%, 2%) rotate(1deg);
    }
    75% {
        transform: translate(2%, 5%) rotate(2deg);
    }
    80% {
        transform: translate(0, 0) rotate(0);
    }
    85% {
        transform: translate(-5%, 0) rotate(-2deg);
    }
    90% {
        transform: translate(-2%, 0) rotate(-1deg);
    }
    95% {
        transform: translate(-5%, 0) rotate(-3deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}

.fade-in-on-scroll {
    opacity: 0;
    transition: all 800ms ease-out;
    transform: translateY(40px);
}

.fade-in-on-scroll.visible {
    opacity: 1;
    transform: translateY(0px);
}

.my-super-cool-btn {
    position: relative;
    text-decoration: none;
    color: #fff;
    letter-spacing: 1px;
    font-size: 2rem;
    box-sizing: border-box;
}

.my-super-cool-btn span {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vw;
    height: 10vw;
}

.my-super-cool-btn span:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 100%;
    border: 7px solid #000000D0;
    box-sizing: border-box;
    transition: all .85s cubic-bezier(0.25, 1, 0.33, 1);
    box-shadow: 0 30px 85px rgba(0, 0, 0, 0.14), 0 15px 35px rgba(0, 0, 0, 0.14);
}

.my-super-cool-btn:hover span:before {
    transform: scale(0.8);
    box-shadow: 0 20px 55px rgba(0, 0, 0, 0.14), 0 15px 35px rgba(0, 0, 0, 0.14);
}

.my-super-cool-btn .dots-container {
    opacity: 0;
    animation: intro 1.6s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn .dot {
    width: 8px;
    height: 8px;
    display: block;
    background-color: #000000D0;
    position: absolute;
    transition: all .85s cubic-bezier(0.25, 1, 0.33, 1);
}

.my-super-cool-btn .dot:nth-child(1) {
    top: 50px;
    left: 50px;
    transform: rotate(-140deg);
    animation: swag1-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn .dot:nth-child(2) {
    top: 50px;
    right: 50px;
    transform: rotate(140deg);
    animation: swag2-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn .dot:nth-child(3) {
    bottom: 50px;
    left: 50px;
    transform: rotate(140deg);
    animation: swag3-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn .dot:nth-child(4) {
    bottom: 50px;
    right: 50px;
    transform: rotate(-140deg);
    animation: swag4-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn:hover .dot:nth-child(1) {
    animation: swag1 0.3s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn:hover .dot:nth-child(2) {
    animation: swag2 0.3s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn:hover .dot:nth-child(3) {
    animation: swag3 0.3s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn:hover .dot:nth-child(4) {
    animation: swag4 0.3s;
    animation-fill-mode: forwards;
}

@keyframes intro {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes swag1 {
    0% {
        top: 50px;
        left: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 20px;
        left: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag1-out {
    0% {
        top: 20px;
        left: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 50px;
        left: 50px;
        width: 8px;
        opacity: 0;
    }
}

@keyframes swag2 {
    0% {
        top: 50px;
        right: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 20px;
        right: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag2-out {
    0% {
        top: 20px;
        right: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 50px;
        right: 50px;
        width: 8px;
        opacity: 0;
    }
}

@keyframes swag3 {
    0% {
        bottom: 50px;
        left: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 20px;
        left: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag3-out {
    0% {
        bottom: 20px;
        left: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 50px;
        left: 50px;
        width: 8px;
        opacity: 0;
    }
}

@keyframes swag4 {
    0% {
        bottom: 50px;
        right: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 20px;
        right: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag4-out {
    0% {
        bottom: 20px;
        right: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 50px;
        right: 50px;
        width: 8px;
        opacity: 0;
    }
}


.about-highlight-title {
    position: relative;
    /*padding: 8px 24px;*/
    /*margin-left: -24px;*/

}

.about-highlight-title:hover {
    /*background-color: var(--base-white-8);*/
    backdrop-filter: blur(6px);
    /*box-shadow: 0 8px 20px #00000008;*/
}

.about-block__content {
    position: absolute;
    /*opacity: 0;*/
    backdrop-filter: blur(6px);
    /*box-shadow: 0 8px 20px #00000008;*/
    /*background-color: #ffffff50;*/
    padding: 40px;
    overflow: hidden;
}

.highlight-progress {
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    /*margin: 0 8px 8px 8px*/
}

.highlight-progress div {
    height: 4px;
    display: flex;
    background-color: var(--base-black-80);
    flex: 1;
    /*margin: 8px;*/
}

.highlight-progress-dark {
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    /*margin: 0 8px 8px 8px*/
}

.highlight-progress-dark div {
    height: 4px;
    display: flex;
    background-color: var(--base-white-80);
    flex: 1;
    /*margin: 8px;*/
}

.highlight {
    background: linear-gradient(104deg, hsla(280, 65%, 85%, 0) 0.9%, #ffb6ff 2.4%, hsla(280, 65%, 85%, 0.5) 5.8%, hsla(280, 65%, 85%, 0.1) 93%, hsla(280, 65%, 85%, 0.7) 96%), linear-gradient(183deg, rgba(188, 130, 255, 0), rgba(255, 152, 196, 0.3) 7.9%, hsla(280, 65%, 85%, 0) 15%);
    border-radius: 7.5px;
    -webkit-box-decoration-break: clone;
    font-weight: bolder;
    margin: 0;
    padding: 0.1em 6px;
    text-shadow: 12px 12px 9.8px #ffb6ff, 21px 18.1px 7.3px #fff, -18.1px -27.3px 30px #fff;
}

.highlight-2 {
    background: linear-gradient(104deg, hsla(280, 65%, 85%, 1) 20%, #ffb6ff 2.4%, hsla(280, 65%, 85%, 1) 5.8%, hsla(280, 65%, 85%, 1) 93%, hsla(280, 65%, 85%, 1) 96%), linear-gradient(183deg, rgba(188, 130, 255, 1), rgba(255, 152, 196, 1) 7.9%, hsla(280, 65%, 85%, 1) 15%);
    border-radius: 7.5px;
    -webkit-box-decoration-break: clone;
    font-weight: bolder;
    margin: 0;
    padding: 0.1em 6px;
    text-shadow: 12px 12px 9.8px #ffb6ff, 21px 18.1px 7.3px #fff, -18.1px -27.3px 30px #fff;
}


.process-item {
    /*background-color: red;*/
    padding: 20px 24px;
    border: 1px solid transparent;
    transition: 300ms ease-in-out;
    color: var(--base-black-70);
}

.process-item:hover {
    /*background-color: var(--base-black-4);*/
    border: 1px solid var(--base-black-20);
    backdrop-filter: blur(12px);
    color: var(--base-black-100);
}

.team-image {
    overflow: hidden;
    box-shadow: 0 8px 20px #00000016;
}

.team-image img {
    transform: scale(1.05);
    transition: 300ms ease-in-out;
}

.team-image:hover img {
    transform: scale(1.1);
}

a {
    text-decoration: none;
}


.case-hero-section {
    height: 80vh;
    background: linear-gradient(83.9deg, #032549 8.2%, #166DB9 90.7%);
    padding: 10vh 0 0 0;
}

.blue-gradient-bg {
    background: radial-gradient(73.16% 78.62% at 50% 50%, #d5e7ff 0%, #f8fcff 100%)
}

.image-slider {
    /*border: 2px solid #00000020;*/
    /*padding:4px 4px 0px 4px;*/
    /*background-color: #00000020;*/
}

.spinner {
    height: 1em;
    width: 1em;
    border: 1px solid #3B81F630;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: #3B81F6;
    animation: spin 1.2s linear infinite;
    box-sizing: border-box;
}

.spin {
    animation: spin 23.2s linear infinite;
}


.hero-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 40px;
    background-color: var(--base-white-20);
    text-align: left;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    box-shadow: 0 8px 20px #00000008;
    padding:20px 40px
}

.hero-box-tablet {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 40px;
    background-color: var(--base-white-20);
    text-align: left;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    box-shadow: 0 8px 20px #00000008;
    padding:12px 20px;
}

.hero-box-mobile {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--base-white-20);
    text-align: left;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    box-shadow: 0 8px 20px #00000008;
    padding:12px 16px
}


@keyframes spin {
    100% {
        transform: rotate(-360deg);
    }
}


.bg-primary {
    background-color: #4848F2;
}


.company-img {
    filter: grayscale(100%);
    opacity: .2;
    transition: 150ms ease;
}

.company-img:hover {
    filter: grayscale(0%);
    opacity: 1;
}

.pill {
    /*background-color: var(--base-white-10);*/
    border: 1px solid var(--base-white-20) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-white-50)
}

.pill:hover {
    background-color: var(--base-white-4);
}

.pill-light {
    /*background-color: var(--base-white-10);*/
    border: 1px solid #00000023 !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-black-50)
}

.pill-light:hover {
    background-color: var(--base-black-4);
}

.pill-inactive {
    /*background-color: var(--base-white-10);*/
    border: 1px solid var(--base-black-10) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-black-30)
}

.pill-inactive:hover {
    background-color: var(--base-white-4);
}

.pill-active {
    background-color: var(--primary-tint);
    border: 1px solid var(--primary-tint) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-white-100)
}

.pill-active:hover {
    background-color: var(--primary-dark);
}

.pill-light-inactive {
    /*background-color: var(--base-white-10);*/
    border: 1px solid var(--base-black-10) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-black-30)
}

.pill-light-inactive:hover {
    background-color: var(--base-black-4);
}

.pill-light-active {
    background-color: var(--primary-tint);
    border: 1px solid var(--primary-tint) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-white-100)
}

.pill-light-active:hover {
    background-color: var(--primary-dark);
}

.dropdown-new {
    position: relative;
}

.dropdown-menu {
    background-color: var(--blacks-dropdown) !important;
    width: max-content !important;
    box-shadow: 0 2px 20px var(--base-black-50) !important;
    border-radius: 8px !important;
    box-sizing: border-box;
}

.dropdown-menu-light {
    background-color: var(--base-white-100) !important;
    width: max-content !important;
    box-shadow: 0 2px 20px var(--primary-light) !important;
    border-radius: 8px !important;
    box-sizing: border-box;
}

.dropdown-menu-option {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    transition: 150ms ease !important;
    padding: 8px 16px !important;
    cursor: pointer !important;
    color: var(--base-white-100) !important;
    min-width: 150px;
    /*body1-medium*/
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
}

.dropdown-menu-option-light {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    transition: 150ms ease !important;
    padding: 8px 16px !important;
    cursor: pointer !important;
    color: var(--base-black-70) !important;
    min-width: 150px;
    /*body1-medium*/
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
}

.dropdown-menu-option:first-child, .dropdown-menu-option-light:first-child {
    border-radius: 12px 12px 0 0;
}

.dropdown-menu-option:last-child, .dropdown-menu-option-light:last-child {
    border-radius: 0 0 12px 12px;
}

.dropdown-menu-option:hover {
    background-color: var(--base-white-10);
}

.dropdown-menu-option.active {
    background-color: var(--base-white-30);
}

.dropdown-menu-option-light:hover {
    background-color: var(--base-black-4);
}

.dropdown-menu-option-light.active {
    background-color: var(--primary-tint);
}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 2px solid var(--base-white-30)
}

.checkbox-active {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 2px solid var(--primary-tint);
    background-color: var(--primary-tint);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bg-brand {
    background: white;
    background-image: radial-gradient(#00000020 1px, transparent 0);
    background-size: 12px 12px;
    background-position: -19px -19px;
}

.burst {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-120%, -50%);
    top: 30%;
    left: 80%;
    width: 400px;
    height: 400px;
    -webkit-filter: blur(80px);
    filter: blur(80px);
    background: radial-gradient(circle at 50% 50%, rgb(0, 42, 255), #4c00ff00);
    opacity: .5;
}

.burst2 {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-280%, 40%);
    top: 30%;
    left: 80%;
    width: 300px;
    height: 300px;
    -webkit-filter: blur(80px);
    filter: blur(80px);
    background: radial-gradient(circle at 50% 50%, rgb(236, 136, 93), #4c00ff00);
    opacity: .7;
}
