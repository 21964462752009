.input-border-basic {
    background: var(--base-black-30);
    padding: 0;
    flex: 1;
}

.input-border-basic.active {
    padding: 1px;
}

.input-border-basic-error {
    background: var(--gradient-pink);
    padding: 0;
    flex: 1;
}

.input-border-basic-error.active {
    padding: 1px;
}

.input-border-basic-error.active .input-container {
    padding: 13.5px 15px 12.5px 15px;
}


.input-container-basic {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14.5px 16px 13.5px 16px;
    background-color: var(--base-black-20);
    border: 1px solid transparent;
    box-sizing: border-box;
}

.input-border-basic.active .input-container-basic {
    padding: 13.5px 15px 12.5px 15px;
}

.input-inner-basic {
    background-color: transparent !important;
    color: var(--base-black-100);
    outline: none !important;
    border: none !important;
    display: flex;
    flex: 1;
    width: 100%;
}

.input-inner-basic::placeholder {
    color: var(--base-black-30);
}

.input-inner-basic:hover::placeholder {
    color: var(--base-black-20);
}

.input-inner-basic:focus {
    outline: none !important;
    border: none !important;
}

.input-inner-basic:-webkit-autofill,
.input-inner-basic:-webkit-autofill:hover,
.input-inner-basic:-webkit-autofill:focus,
.input-inner-basic:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
    -webkit-text-fill-color: var(--base-white-100) !important;
}





.nav-input-border-basic {
    border-radius: 10px;
    background: var(--gradient-hotpink);
    padding: 0;
    flex: 1;
}

.nav-input-border-basic.active {
    padding: 1px;
}


.nav-input-container-basic {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7.5px 11px 6.5px 11px;
    background-color: #000000AD;
    border-radius: 10px;
    border: 1px solid transparent;
    box-sizing: border-box;
}

.nav-input-border-basic.active .nav-input-container-basic {
    padding: 6.5px 10px 5.5px 10px;
}

.nav-input-inner-basic {
    background-color: transparent !important;
    color: var(--base-white-100);
    outline: none !important;
    border: none !important;
    display: flex;
    flex: 1;
    width: 100%;
}

.nav-input-inner-basic::placeholder {
    color: var(--base-white-50);
}

.nav-input-inner-basic:hover::placeholder {
    color: var(--base-white-30);
}

.nav-input-inner-basic:focus {
    outline: none !important;
    border: none !important;
}

.nav-input-inner-basic:-webkit-autofill,
.nav-input-inner-basic:-webkit-autofill:hover,
.nav-input-inner-basic:-webkit-autofill:focus,
.nav-input-inner-basic:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
    -webkit-text-fill-color: var(--base-white-100) !important;
}

.nav-input-inner-basic-light {
    background-color: transparent !important;
    color: var(--base-black-100);
    outline: none !important;
    border: none !important;
    display: flex;
    flex: 1;
    width: 100%;
}

.nav-input-inner-basic-light::placeholder {
    color: var(--base-black-30);
}

.nav-input-inner-basic-light:hover::placeholder {
    color: var(--base-black-20);
}

.nav-input-inner-basic-light:focus {
    outline: none !important;
    border: none !important;
}

.nav-input-inner-basic-light:-webkit-autofill,
.nav-input-inner-basic-light:-webkit-autofill:hover,
.nav-input-inner-basic-light:-webkit-autofill:focus,
.nav-input-inner-basic-light:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
    -webkit-text-fill-color: var(--base-white-100) !important;
}








