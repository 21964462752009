.modal-new-open {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1050 !important;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 150ms ease;
    outline: none !important;
    box-sizing: border-box;
    overflow: hidden;
}

.modal-content {
    background-color: var(--base-black-100);
    border: 2px solid var(--base-white-20);
    padding: var(--space24);
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    /*overflow: scroll;*/
    border-radius: 20px;
    box-shadow: 0 12px 20px var(--base-black-20);
}

.modal-content-xs {
    min-width: 300px;
    max-width: 300px;
}

.modal-content-sm {
    min-width: 383px;
    max-width: 383px;
}

.modal-content-md {
    min-width: 500px;
    max-width: 500px;
}

.modal-content-lg {
    min-width: 800px;
    max-width: 800px;
}

.modal-content-xl {
    width: 1140px;
    max-width: min(1140px, 95vw);
}

.modal-content-full {
    width: 100vw;
    height: 100vh;
}

.modal-content-full-mobile {
    width: 100vw;
    min-height: 100vh;
}

.close-modal-icon {
    transition: transform 300ms ease-in-out;
    transform: scale(.8);
}

.close-modal-icon:hover {
    transform: rotate(-90deg) scale(1);
}
